
.colapse {
overflow: hidden;
transition: max-height .7s ease;
max-height: 0;
}
.expand {
    max-height: 1000px;
}
.show-overflow{
    overflow: visible !important;
}