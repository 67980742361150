.lds-dual-ring {
    display: inline-block;
  }
.lds-dual-ring:after {
content: " ";
display: block;
border-radius: 50%;
margin-left: 8px;
border: 3px solid #0834AB;
border-color: #0834AB transparent #0834AB transparent;
animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
}